import {Typography, OssAttributionLink} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {withTranslation} from 'react-i18next'

const styles = {
  root: {
    backgroundColor: '#00374D',
    padding: '8px 24px',
    textAlign: 'right'
  },

  buildVersionText: {
    color: 'white',
    opacity: 0.3
  }
}

function Footer({t, classes, children}) {
  return (
    <Box className={classes.root} display="flex" justifyContent="space-between">
      <OssAttributionLink />
      <Typography variant="caption" className={classes.buildVersionText}>
        <>{children}</>
      </Typography>
    </Box>
  )
}

export default withTranslation()(withStyles(styles)(Footer))
