import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ToggleLayer, anchor} from 'react-laag'

import MapCard from '../MapCard'

import signalUrl from './signal.svg'

const nativeWidth = 24
const nativeHeight = 24
const iconScale = 0.75

const styles = (theme) => ({
  signal: {
    // this is required as the popup is in the same
    // positioning layer and without it the icon consumes space
    // which leads to a move by the icon size of the popup.
    position: 'absolute',

    // native image size: 51x68
    width: `${nativeWidth * iconScale}px`,
    height: `${nativeHeight * iconScale}px`,

    // horizontal center and vertical bottom
    // so that the pointer is pointing to the actual GPS coord
    transform: 'translate(-50%, -50%)',

    cursor: 'pointer'
  }
})

const SignalMarker = ({classes, lat, lng, timestamp}) => {
  const {t} = useTranslation()
  const timeTitle = `${t('signal_card.timestamp')}: ${timestamp.toLocaleTimeString()}`

  return (
    <ToggleLayer
      closeOnOutsideClick
      // provide placement config
      placement={{anchor: anchor.TOP_CENTER, autoAdjust: true, scrollOffset: 56}}
      // render-prop to render our layer
      renderLayer={({layerProps, isOpen, close, arrowStyle, layerSide}) =>
        // only render on `isOpen`
        isOpen && (
          <div ref={layerProps.ref} style={layerProps.style}>
            <MapCard
              title={timeTitle}
              onClose={close}
              arrowStyle={arrowStyle}
              layerSide={layerSide}
            >
              <p>
                Lat/Long: {lat.toFixed(3)}, {lng.toFixed(3)}
              </p>
            </MapCard>
          </div>
        )
      }
    >
      {({toggle, triggerRef}) => (
        <img
          className={classes.signal}
          lat={lat}
          lng={lng}
          role="button"
          alt={timeTitle}
          title={timeTitle}
          src={signalUrl}
          // only the `triggerRef` is required...
          ref={triggerRef}
          // ...the rest is up to you
          onClick={toggle}
        />
      )}
    </ToggleLayer>
  )
}

SignalMarker.propTypes = {
  classes: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired
}

export default withStyles(styles)(SignalMarker)
