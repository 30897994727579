import {setErrorMessage} from '../App.action'

const requestTrucks = (data) => ({
  type: 'REQUEST_TRUCKS',
  data
})

const requestDispatchGroups = (data) => ({
  type: 'REQUEST_DISPATCH_GROUPS',
  data
})

const requestPlants = (data) => ({
  type: 'REQUEST_PLANTS',
  data
})

const requestPositions = (data) => ({
  type: 'REQUEST_POSITIONS',
  data
})

export const resetPositions = (data) => ({
  type: 'RESET_POSITIONS',
  data
})

export const selectDispatchGroup = (data) => ({
  type: 'SELECT_DISPATCH_GROUP',
  data
})

export const selectTimeRange = (data) => ({
  type: 'SELECT_TIME_RANGE',
  data
})

export const selectRoute = (data) => ({
  type: 'SELECT_ROUTE',
  data
})

export const selectTruck = (data) => ({
  type: 'SELECT_TRUCK',
  data
})

export const selectPlant = (data) => ({
  type: 'SELECT_PLANT',
  data
})

export const setPolling = (data) => ({
  type: 'SET_POLLING',
  data
})

export const fetchTrucks = () => async (dispatch, getState, {api}) => {
  // Prevent duplicate requests
  if (getState().map.trucks.isFetching) {
    return
  }

  const params = {}

  // FIXME: Make use of selector
  const {selectedDispatchGroup, selectedPlant} = getState().map.interactive

  if (selectedDispatchGroup) {
    params.dispatchGroup = selectedDispatchGroup
  }

  if (selectedPlant) {
    params.plantId = selectedPlant
  }

  if (!selectedDispatchGroup && !selectedPlant) {
    console.log('Waiting to fetch trucks (requires dispatch group or plant selection)')
    dispatch({
      type: 'RECEIVE_TRUCKS',
      payload: null
    })
    return
  }

  // inform UI that we are going to load data now
  dispatch(requestTrucks())
  console.log('Fetching trucks...')

  try {
    const response = await api.get('/trucks', {params})
    if (!response) {
      throw new Error('Did not received a response object!')
    }

    console.log(`Fetched ${response.data.length} trucks.`)

    dispatch({
      type: 'RECEIVE_TRUCKS',
      payload: response.data,
      receivedAt: new Date()
    })

    dispatch({
      type: 'CHECK_TRUCK_SELECTION',
      data: response.data
    })
  } catch (error) {
    dispatch({
      type: 'FAILED_TRUCKS'
    })
    dispatch(setErrorMessage('error.fetch.trucks'))
    console.warn(`Error Fetching Trucks: ${error}`)
  }
}

export const fetchDispatchGroups = () => async (dispatch, getState, {api}) => {
  // Prevent duplicate requests
  if (getState().map.dispatchGroups.isFetching) {
    return
  }

  // inform UI that we are going to load data now
  dispatch(requestDispatchGroups())
  console.log('Fetching dispatch groups...')

  const params = {}

  try {
    const response = await api.get('/plants', {params})
    if (!response) {
      throw new Error('Did not received a response object!')
    }

    console.log('Fetching dispatch groups: Done')
    dispatch({
      type: 'RECEIVE_DISPATCH_GROUPS',
      payload: response.data,
      receivedAt: new Date()
    })
  } catch (error) {
    dispatch({
      type: 'FAILED_DISPATCH_GROUPS'
    })
    dispatch(setErrorMessage('error.fetch.dispatchGroups'))
    console.warn(`Error Fetching Dispatch Groups: ${error}`)
  }
}

export const fetchPlants = () => async (dispatch, getState, {api}) => {
  // Prevent duplicate requests
  if (getState().map.plants.isFetching) {
    return
  }

  // inform UI that we are going to load data now
  dispatch(requestPlants())

  const params = {}

  // FIXME: Make use of selector
  const {selectedDispatchGroup} = getState().map.interactive

  if (selectedDispatchGroup) {
    params.dispatchGroup = selectedDispatchGroup
  }

  console.log('Fetching plants...')
  // try {
  const response = await api.get('/plants', {params})
  if (!response) {
    throw new Error('Did not received a response object!')
  }

  console.log(`Fetched ${response.data.length} plants.`)
  dispatch({
    type: 'RECEIVE_PLANTS',
    payload: response.data,
    receivedAt: new Date()
  })
  // } catch (error) {
  //   dispatch({
  //     type: 'FAILED_PLANTS'
  //   })
  //   dispatch(setErrorMessage('error.fetch.plants'))
  //   console.warn('Error Fetching Plants: ' + error)
  // }
}

export const fetchPositions = () => async (dispatch, getState, {api}) => {
  // Prevent duplicate requests
  if (getState().map.positions.isFetching) {
    return
  }

  // FIXME: Make use of selector
  const {selectedTruck, selectedTimeRange} = getState().map.interactive

  // We can only work if we have these two values correctly set.
  if (!selectedTruck || !selectedTimeRange) {
    return
  }

  const requestTrackingData = {selectedTruck, selectedTimeRange}

  const params = {
    startDate: selectedTimeRange.start.toISOString(),
    endDate: selectedTimeRange.end.toISOString()
  }

  // inform UI that we are going to load data now
  dispatch(requestPositions(requestTrackingData))

  console.log('Fetching positions...')

  try {
    const response = await api.get(`/trucks/${selectedTruck}/positions`, {
      params
    })
    if (!response) {
      throw new Error('Did not received a response object!')
    }
    console.log(`Fetched ${response.data.length} positions!`)

    if (response.data.length === 0) {
      setErrorMessage('System did not transferred current positions for selected truck.')
    }

    dispatch({
      type: 'RECEIVE_POSITIONS',
      payload: response.data,
      receivedAt: new Date()
    })
  } catch (error) {
    dispatch({
      type: 'FAILED_POSITIONS'
    })
    dispatch(setErrorMessage('error.fetch.positions'))
    console.warn(`Error Fetching Positions: ${error}`)
  }
}
