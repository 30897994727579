/* eslint-disable no-case-declarations, max-depth, no-shadow */

import {endOfToday, isToday, startOfToday} from 'date-fns'
import {last} from 'lodash'
import ms from 'ms'
import queryString from 'query-string'
import {combineReducers} from 'redux'

import {store} from '../App.store'

import {
  fetchPlants,
  fetchPositions,
  fetchTrucks,
  resetPositions,
  selectDispatchGroup,
  selectPlant,
  selectTruck
} from './Map.action'

const POLLING_INTERVAL = ms('1m')

// We think of that a pause of 10 minutes is an actual pausing for trucks.
// This is longer than the typical traffic light or stop sign pause.
const MIN_PAUSE_TIME = ms('10m')

function getTimeAsId(date) {
  return date.toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  })
}

// Required movement: 50m => 0.05km
const MIN_REQUIREMENT_MOVEMENT = 0.05

function hasMoved(first, second) {
  // A trivial implementation would just look for identity
  // item.lat !== previousItem.lat || item.lng !== previousItem.lng

  // Unfortunately this would track a lot of non real movements e.g.
  // when driving around at the same location basically e.g. the Plant.

  return distanceInKmBetweenEarthCoordinates(first, second) > MIN_REQUIREMENT_MOVEMENT
}

function reduceLocationPrecision(latOrLng) {
  return parseFloat(latOrLng, 10)
}

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180
}

const EARTH_RADIUS_KM = 6371

function distanceInKmBetweenEarthCoordinates(first, second) {
  let lat1 = first.lat
  const lng1 = first.lng
  let lat2 = second.lat
  const lng2 = second.lng

  const dLat = degreesToRadians(lat2 - lat1)
  const dLon = degreesToRadians(lng2 - lng1)

  lat1 = degreesToRadians(lat1)
  lat2 = degreesToRadians(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return EARTH_RADIUS_KM * c
}

function computeDistance(signals) {
  let distance = 0
  let prevSignal = null

  signals.forEach((signal) => {
    if (prevSignal) {
      distance += distanceInKmBetweenEarthCoordinates(signal, prevSignal)
    }

    prevSignal = signal
  })

  return distance.toFixed(1)
}

function compareName(a, b) {
  const firstName = a.name.toUpperCase()
  const secondName = b.name.toUpperCase()
  if (firstName < secondName) {
    return -1
  }
  if (firstName > secondName) {
    return 1
  }
  return 0
}

function compareTrucks(a, b) {
  const firstActive = a.active || false
  const secondActive = b.active || false

  if (firstActive === secondActive) {
    return compareName(a, b)
  }
  if (firstActive) {
    return -1
  }
  return 1
}

const trucks = (
  state = {
    isFetching: false,
    items: [],
    activeItems: []
  },
  action
) => {
  switch (action.type) {
    case 'REQUEST_TRUCKS':
      return {
        ...state,
        isFetching: true
      }

    case 'FAILED_TRUCKS':
      return {
        ...state,
        isFetching: false
      }

    case 'RECEIVE_TRUCKS':
      const items = action.payload || []

      // FIXME: Adapting to unified data structure. Something which is
      // ideally done on the backend.
      const cleanItems = items.map((truck) => {
        const {truckId, truckNumber, truckName, lastPosition, ...copied} = truck

        const cleanTruck = {
          id: truckId,
          number: truckNumber,
          name: truckName,
          ...copied
        }

        if (lastPosition) {
          cleanTruck.lastPosition = {
            timestamp: new Date(lastPosition.timestamp),
            lat: reduceLocationPrecision(lastPosition.lat),
            lng: reduceLocationPrecision(lastPosition.lng)
          }
        }

        return cleanTruck
      })

      const activeItems = cleanItems.slice().filter((item) => {
        if (!item.lastPosition) {
          return false
        }

        const isActive = isToday(item.lastPosition.timestamp)
        item.active = isActive

        return isActive
      })

      cleanItems.sort(compareTrucks)
      activeItems.sort(compareName)

      return {
        ...state,
        isFetching: false,
        items: cleanItems,
        activeItems,
        lastUpdated: action.receivedAt
      }

    default:
      return state
  }
}

const dispatchGroups = (
  state = {
    isFetching: false,
    items: []
  },
  action
) => {
  switch (action.type) {
    case 'REQUEST_DISPATCH_GROUPS':
      return {
        ...state,
        isFetching: true
      }

    case 'FAILED_DISPATCH_GROUPS':
      return {
        ...state,
        isFetching: false
      }

    case 'RECEIVE_DISPATCH_GROUPS':
      const plants = action.payload || []

      const dispatchGroups = {}
      plants.forEach((plant) => {
        const groupId = plant.dispatchGroup
        if (!dispatchGroups[groupId]) {
          dispatchGroups[groupId] = {
            countries: new Set(),
            types: new Set(),
            plants: []
          }
        }

        dispatchGroups[groupId].plants.push(plant.plantId)
        dispatchGroups[groupId].countries.add(plant.country)
        dispatchGroups[groupId].types.add(plant.plantType)
      })

      const dispatchGroupIds = Object.keys(dispatchGroups).sort()
      const items = dispatchGroupIds.map((groupId) => ({
        id: groupId,
        countries: [...dispatchGroups[groupId].countries],
        types: [...dispatchGroups[groupId].types]
      }))

      return {
        ...state,
        isFetching: false,
        items,
        lastUpdated: action.receivedAt
      }

    default:
      return state
  }
}

const plants = (
  state = {
    isFetching: false,
    items: []
  },
  action
) => {
  switch (action.type) {
    case 'REQUEST_PLANTS':
      return {
        ...state,
        isFetching: true
      }

    case 'FAILED_PLANTS':
      return {
        ...state,
        isFetching: false
      }

    case 'RECEIVE_PLANTS':
      const items = action.payload || []

      // FIXME: Adapting to unified data structure. Something which is
      // ideally done on the backend.
      const cleanItems = items.map((plant) => {
        const {lat, lng, plantId, plantName, plantNumber, plantType, ...copied} = plant

        return {
          id: `${plantId}-${plantNumber}`,
          name: plantName,
          type: plantType,
          number: plantNumber,
          lat: reduceLocationPrecision(lat),
          lng: reduceLocationPrecision(lng),
          ...copied
        }
      })

      return {
        ...state,
        isFetching: false,
        items: cleanItems,
        lastUpdated: action.receivedAt
      }

    default:
      return state
  }
}

const positions = (
  state = {
    isFetching: false,
    routes: [],
    lastPosition: {}
  },
  action
) => {
  switch (action.type) {
    case 'REQUEST_POSITIONS':
      return {
        ...state,
        isFetching: true
      }

    case 'FAILED_POSITIONS':
      return {
        ...state,
        isFetching: false
      }

    case 'RESET_POSITIONS':
      return {
        ...state,
        routes: []
      }

    case 'RECEIVE_POSITIONS':
      const items = action.payload || []

      // Parse position numbers into floats + parse timestamp into Date
      const cleanItems = items.map((item) => ({
        lat: reduceLocationPrecision(item.lat),
        lng: reduceLocationPrecision(item.lng),
        timestamp: new Date(item.timestamp)
      }))

      // Make sure that positions are sorted by time stamp
      cleanItems.sort((a, b) => a.timestamp - b.timestamp)

      // This should most probably be done on the API as well...
      const routes = []
      let currentRoute = []

      let previousItem = null
      cleanItems.forEach((item) => {
        if (!previousItem || hasMoved(item, previousItem)) {
          // Update previous entry when data of current item "arrives"
          if (previousItem) {
            previousItem.duration = item.timestamp - previousItem.timestamp
            previousItem.paused = previousItem.duration > MIN_PAUSE_TIME
            if (previousItem.paused) {
              // Drop routes which only contain one location. These are
              // not really routes by the human definition.
              if (currentRoute.length > 1) {
                routes.push(currentRoute)
              }

              // Create a new route for the next items
              currentRoute = []
            }
          }

          currentRoute.push(item)
          previousItem = item
        }
      })

      // Drop routes which only contain one location. These are
      // not really routes by the human definition.
      if (currentRoute.length > 1) {
        routes.push(currentRoute)
      }

      const routesWithIds = routes.map((signals, index) => {
        const firstSignal = signals[0]
        const lastSignal = last(signals)

        const timeId = getTimeAsId(firstSignal.timestamp)

        return {
          id: `R-${timeId}+`,
          signals,
          startLat: firstSignal.lat,
          startLng: firstSignal.lng,
          endLat: lastSignal.lat,
          endLng: lastSignal.lng,
          duration: lastSignal.timestamp - firstSignal.timestamp,
          distance: computeDistance(signals),
          from: firstSignal.timestamp,
          to: lastSignal.timestamp
        }
      })

      return {
        ...state,
        isFetching: false,
        routes: routesWithIds,
        lastPosition: last(currentRoute),
        lastUpdated: action.receivedAt
      }

    default:
      return state
  }
}

function autoUpdateMap() {
  store.dispatch(fetchPlants())
  store.dispatch(fetchTrucks())
  store.dispatch(fetchPositions())
}

export function initFromQueryString() {
  const parsed = queryString.parse(window.location.search)

  if (parsed.dispatchGroup) {
    store.dispatch(selectDispatchGroup(parsed.dispatchGroup))

    if (parsed.truck) {
      store.dispatch(selectTruck(parsed.truck))
    }
  }
}

window.setTimeout(initFromQueryString, 50)

const interactive = (
  state = {
    dependencyCount: 0,
    selectedTruck: null,
    selectedPlant: null,
    selectedDispatchGroup: null,
    selectedTimeRange: {
      start: startOfToday(),
      end: endOfToday()
    },
    selectedRoute: null
  },
  action
) => {
  switch (action.type) {
    case 'SET_POLLING':
      const oldValue = state.dependencyCount
      const newValue = oldValue + (action.data ? 1 : -1)

      let handle = state.dependencyHandle

      if (oldValue === 0) {
        // Schedule direct update in next cycle
        requestAnimationFrame(autoUpdateMap)

        // Afterwards start the update interval to keep data up-to-date
        handle = window.setInterval(autoUpdateMap, POLLING_INTERVAL)
      }

      if (newValue === 0) {
        window.clearInterval(handle)
        handle = null
      }

      return {
        ...state,
        dependencyCount: newValue,
        dependencyHandle: handle
      }

    case 'SELECT_DISPATCH_GROUP':
      requestAnimationFrame(() => {
        // When resetting the dispatch group make sure to also
        // reset the currently selected truck and plant.
        if (state.selectedDispatchGroup) {
          store.dispatch(selectPlant(null))
          store.dispatch(selectTruck(null))
        }

        // It is also a good idea to reset the previously selected truck as it
        // might not be available anymore in the next dispatch group.
        store.dispatch(fetchPlants())
        store.dispatch(fetchTrucks())
      })

      return {
        ...state,
        selectedDispatchGroup: action.data
      }

    case 'SELECT_PLANT':
      requestAnimationFrame(() => {
        // Send out new action to async load truck data whenever a new plant
        // is being selected
        store.dispatch(selectTruck(null))

        // It is also a good idea to reset the previously selected truck as it
        // might not be assigned to this plant.
        store.dispatch(fetchTrucks())
      })

      return {
        ...state,
        selectedPlant: action.data
      }

    case 'SELECT_TRUCK':
      // Send out new action to async load position data whenever a new truck
      // is being selected
      if (action.data) {
        requestAnimationFrame(() => store.dispatch(fetchPositions()))
      } else {
        requestAnimationFrame(() => store.dispatch(resetPositions()))
      }

      return {
        ...state,
        selectedTruck: action.data
      }

    case 'CHECK_TRUCK_SELECTION':
      if (action.data) {
        const selectedTruck = state.selectedTruck
        const selectedTruckIsInList = action.data.some((item) => item.truckId === selectedTruck)

        return {
          ...state,
          selectedTruck: selectedTruckIsInList ? selectedTruck : null
        }
      }

      return state

    case 'SELECT_TIME_RANGE':
      // Send out new action to async load position data whenever a new date
      // is being selected
      requestAnimationFrame(() => store.dispatch(fetchPositions()))

      return {
        ...state,
        selectedTimeRange: action.data
      }

    case 'SELECT_ROUTE':
      return {
        ...state,
        selectedRoute: action.data
      }

    default:
      return state
  }
}

export default combineReducers({
  interactive,
  trucks,
  plants,
  dispatchGroups,
  positions
})
