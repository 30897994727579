import {routerMiddleware} from 'connected-react-router'
import React from 'react'
import {Provider} from 'react-redux'
import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction'
import thunk from 'redux-thunk'

import appReducer from './App.reducer'

import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  EnvAuthRequestProvider,
  createHttpClient,
  getDefaultDFBackends
} from '@hconnect/apiclient'
import {History} from '@hconnect/uikit'

// Note: for production we have to use a different auth host
// Note: This is not send over as a parameter. This is directly used by the UI
// component to redirect to the matching auth host.
// authenticatorHost: SELECTED_ENV.authenticatorHost,

const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
const selectedBackend = backendSelector.getSelectedBackend()

export const loginStorage = new BrowserLoginStorage(
  `HC-${process.env.REACT_APP_CLIENT_NAME}-${selectedBackend.NAME}`
)

const authRequestProvider = new EnvAuthRequestProvider(backendSelector)
export const loginFlow = new BrowserLoginFlow(loginStorage, authRequestProvider, backendSelector)

const api = new createHttpClient({
  backendSelector,
  authRequestProvider,
  loginStorage,
  loginFlow
})

const middleware = [thunk.withExtraArgument({api}), routerMiddleware(History)]

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
})

export const devStore = (initialState) =>
  createStore(appReducer(History), initialState, composeEnhancers(applyMiddleware(...middleware)))

function configureStore(initialState = {}) {
  return devStore(initialState)
}

export const store = configureStore()

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./App.reducer', () => {
      /* eslint-disable-next-line @typescript-eslint/no-var-requires */
      const nextReducer = require('./App.reducer').default
      store.replaceReducer(nextReducer(History))
    })
  }
}

export function withStore(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <Provider store={store}>
          <WrappedComponent {...this.props} />
        </Provider>
      )
    }
  }
}
