import {Chip} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

const styles = (theme) => ({
  cluster: {
    background: '#fff',
    zIndex: '1000'
  },
  truckIcon: {
    color: '#00374D'
  }
})

const ClusterMarker = ({classes, lat, lng, truckCount}) => {
  const {t} = useTranslation()
  return (
    // The outer span is required to make the 'Chip' layout work correctly in IE11.
    <span style={{display: 'flex', width: '250px'}}>
      <Chip
        className={classes.cluster}
        lat={lat}
        lng={lng}
        variant="outlined"
        fontSize="large"
        label={`${truckCount} ${t('truck_cluster.text')}`}
      />
    </span>
  )
}

ClusterMarker.propTypes = {
  classes: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  truckCount: PropTypes.number.isRequired
}

export default withStyles(styles)(ClusterMarker)
