import React from 'react'

import Footer from '../components/Footer'

import {EnvAppInfoContainer} from '@hconnect/uikit'

export default function FooterContainer() {
  return (
    <Footer>
      <EnvAppInfoContainer />
    </Footer>
  )
}
