import axios, {AxiosResponse} from 'axios'
import jwtDecode from 'jwt-decode'
import qs from 'query-string'

import {requestRevokeRefreshToken} from '../interceptors'
import {convertAuthToApiToken} from '../tools'
import {
  AuthRequestProvider,
  BackendSelector,
  DecodedToken,
  LoginFlowState,
  LoginResponse,
  LoginStorage,
  ClientConfig
} from '../types'

type TokenResponse = {
  id_token: string
  access_token: string
  expires_in: number
  token_type: string
  refresh_token: string
  scope: string
}

type AuthRedirectQueryParams = {
  code: string
  scope: string
  state: string
  session_state: string
  error?: string
  error_description?: string
}

// This class takes care of communicating with the Authenticator App (send/recv redirects)
export class BrowserLoginFlow {
  constructor(
    storage: LoginStorage,
    authRequestProvider: AuthRequestProvider,
    backendSelector: BackendSelector
  ) {
    this.storage = storage
    this.authRequestProvider = authRequestProvider
    this.authenticatorUrl = backendSelector.getSelectedBackend().AUTH_URL
    this.backendSelector = backendSelector
  }

  storage: LoginStorage
  authRequestProvider: AuthRequestProvider
  authenticatorUrl: string
  backendSelector: BackendSelector

  async startLogoutProcess() {
    const signOutRequest = this.authRequestProvider.createSignOutRequest(this.storage)
    const clientConfig: ClientConfig = {
      loginStorage: this.storage,
      authRequestProvider: this.authRequestProvider,
      loginFlow: this,
      backendSelector: this.backendSelector
    }
    // before logout revoke refresh token
    await requestRevokeRefreshToken(clientConfig)

    // clear token on product side
    this.storage.resetToken()
    window.location.href = signOutRequest.url
  }

  /**
   * Redirects to the Authenticator application for letting the user log-in.
   */
  async startLoginProcess() {
    const signInRequest = await this.authRequestProvider.createSignInRequest()
    this.storage.setFlow(signInRequest.flow)
    window.location.href = signInRequest.url
  }

  /**
   * Redirects to the Authenticator application for letting the user create account or request access.
   */
  async startRegistrationProcess(
    registrationType: 'create-account' | 'request-access',
    countryId = ''
  ) {
    const registrationRequest = await this.authRequestProvider.createRegistrationRequest(
      registrationType,
      countryId
    )
    this.storage.setFlow(registrationRequest.flow)
    window.location.href = registrationRequest.url
  }

  /**
   * Processes the URL parameters added when coming back from a successful login,
   * stores them locally for the following API requests.
   */
  async getLoginState(): Promise<LoginResponse> {
    const isSignIn = window.location.href.indexOf(`${window.origin}/auth`) === 0
    const isSignOut = window.location.href.indexOf(`${window.origin}/auth/callback/logout`) === 0

    if (!isSignIn && !isSignOut) {
      const {accessToken} = this.storage.getToken()
      if (!accessToken) {
        return {loggedIn: false}
      }
      const decodedToken = jwtDecode<DecodedToken>(accessToken)
      return {loggedIn: true, decodedToken}
    }

    if (isSignOut) {
      // Remove our own fields which are just use temporary during
      // exchange with the authentication system.
      this.storage.resetFlow()
      window.history.replaceState(null, '', window.origin)
      return {loggedIn: false}
    }

    try {
      const flowState = this.storage.getFlow()
      const tokenResponse = await this.exchangeAuthorizationCode(flowState)

      if (tokenResponse.access_token && tokenResponse.refresh_token) {
        const token = convertAuthToApiToken({
          id_token: tokenResponse.id_token,
          access_token: tokenResponse.access_token,
          refresh_token: tokenResponse.refresh_token
        })
        // Store the rest using the native method
        this.storage.setToken(token)

        // Remove our own fields which are just use temporary during
        // exchange with the authentication system.
        this.storage.resetFlow()

        const decodedToken = jwtDecode<DecodedToken>(tokenResponse.access_token)

        if (flowState.href) {
          const [, path] = flowState.href.split(window.origin)
          window.location.replace(path)
          return {loggedIn: true, decodedToken}
        }
        return {loggedIn: true, decodedToken}
      } else {
        console.warn('Received no access or refresh token')
        window.history.replaceState(null, '', window.origin)
        return {loggedIn: false}
      }
    } catch (error) {
      console.error(error)
      window.history.replaceState(null, '', window.origin)
      return {loggedIn: false}
    }
  }

  private async exchangeAuthorizationCode(flowState: LoginFlowState): Promise<TokenResponse> {
    const queryParams = qs.parse(window.location.search) as AuthRedirectQueryParams
    if (queryParams.error) {
      throw new Error(`There has been an error while signin: ${queryParams.error_description}`)
    }
    if (!queryParams.code) {
      throw new Error('There was no code returned from IdentityServer, aborting login flow.')
    }
    if (flowState.state !== queryParams.state) {
      throw new Error('There has been a state mismatch.')
    }

    const params = new URLSearchParams()
    params.append('grant_type', 'authorization_code')
    params.append('client_id', this.authRequestProvider.getClientId())
    params.append('code', queryParams.code)
    params.append('code_verifier', flowState.codeVerifier)
    params.append('redirect_uri', this.authRequestProvider.getAuthSuccessRedirectUrl())

    const tokenResponse = (await axios({
      method: 'post',
      url: `${new URL(this.authenticatorUrl).origin}/api/identity/connect/token`,
      data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })) as AxiosResponse<TokenResponse>

    return tokenResponse.data
  }
}
