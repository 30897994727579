import {withStyles} from '@material-ui/core/styles'
import {motion} from 'framer-motion'
import React from 'react'

import arrowUrl from './arrow-10.svg'

function getArrowTranslate(layerSide) {
  let x = '-50%'
  let y = 0

  const OFFSET = 0
  if (layerSide === 'left') {
    x = `${-OFFSET}px`
    y = '-50%'
  } else if (layerSide === 'right') {
    x = `${OFFSET}px`
    y = '-50%'
  }

  const rotation = {
    top: 180,
    right: -90,
    left: 90,
    bottom: 0
  }

  return `translate(${x}, ${y}) rotate(${rotation[layerSide]}deg)`
}

const outTransform = {
  top: {x: 0, y: -20},
  left: {x: -20, y: 0},
  bottom: {x: 0, y: 20},
  right: {x: 20, y: 0}
}

const styles = (theme) => ({
  popup: {
    willChange: 'transform',
    position: 'relative',
    pointerEvents: 'auto',
    cursor: 'default',
    background: 'white',
    borderRadius: '8px',
    filter: 'drop-shadow(0 0 2px #888) drop-shadow(0 0 40px #aaa)',
    color: 'rgba(0, 0, 0, 0.6)'
  }
})

const Arrow = (props) => <img width="10" height="10" alt="arrow" src={arrowUrl} {...props} />

const Menu = React.forwardRef(({classes, style, arrowStyle, layerSide, children}, ref) => (
  <motion.div
    ref={ref}
    className={classes.popup}
    style={style}
    initial={{opacity: 0, scale: 0.85, ...outTransform[layerSide]}} // animate from
    animate={{opacity: 1, scale: 1, x: 0, y: 0}} // animate to
    exit={{opacity: 0, scale: 0.85, ...outTransform[layerSide]}} // animate exit
    transition={{
      type: 'spring',
      stiffness: 800,
      damping: 35
    }}
  >
    {children}

    <Arrow
      style={{
        ...arrowStyle,
        position: 'absolute',
        willChange: 'transform',
        transformOrigin: 'center',
        transform: getArrowTranslate(layerSide)
      }}
    />
  </motion.div>
))

export default withStyles(styles)(Menu)
