import {IconButton, Snackbar, SnackbarContent} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'

const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
    boxShadow: 'none',
    border: 'none'
  },
  bar: {
    backgroundColor: 'rgba(0, 0, 0, 0.80)',
    color: theme.palette.common.white
  }
})

class ErrorBoundary extends Component {
  state = {
    hasError: false
  }

  clearErrorMessage() {
    this.setState({hasError: false})
  }

  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  componentDidCatch(error, errorInfo) {
    console.warn('Uncaught error:', error, errorInfo)
  }

  render() {
    const {classes, t, children} = this.props
    const {hasError} = this.state
    if (!hasError) {
      return children
    }
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          open={hasError}
          onClose={() => this.clearErrorMessage()}
        >
          <SnackbarContent
            className={classes.bar}
            aria-describedby="message-id"
            message={<span id="message-id">{t('error.unknown')}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={() => this.clearErrorMessage()}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Snackbar>
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles)(ErrorBoundary))
