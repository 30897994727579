import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import {ToggleLayer, anchor} from 'react-laag'

import MapCard from '../MapCard'

import plantPlain from './plant-plain.svg'
import iconUrl from './plant.svg'

const nativeWidth = 51
const nativeHeight = 68
const iconScale = 0.6

const styles = (theme) => ({
  plant: {
    // this is required as the popup is in the same
    // positioning layer and without it the icon consumes space
    // which leads to a move by the icon size of the popup.
    position: 'absolute',

    // native image size: 51x68
    width: `${nativeWidth * iconScale}px`,
    height: `${nativeHeight * iconScale}px`,

    // horizontal center and vertical bottom
    // so that the pointer is pointing to the actual GPS coord
    transform: 'translate(-50%, -90%)',

    filter: 'drop-shadow(0 1px 1px #333)',
    cursor: 'pointer'
  },
  details: {
    marginTop: '12px',
    marginBottom: '0px',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    '& > p': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      marginTop: '0px',
      marginBottom: '8px',

      '&:last-child': {
        marginBottom: '0px'
      }
    }
  },
  rightText: {
    float: 'right',
    marginLeft: '12px'
  }
})

const Plant = ({
  classes,
  number,
  type,
  name,
  street,
  city,
  postalCode,
  organizationalUnit,
  dispatchGroup,
  lat,
  lng
}) => (
  <ToggleLayer
    closeOnOutsideClick
    // provide placement config
    placement={{
      anchor: anchor.TOP_CENTER,
      autoAdjust: true,
      triggerOffset: 10,
      scrollOffset: 56
    }}
    // render-prop to render our layer
    renderLayer={({layerProps, isOpen, close, arrowStyle, layerSide}) =>
      // only render on `isOpen`
      isOpen && (
        <div ref={layerProps.ref} style={layerProps.style}>
          <MapCard
            title={name}
            icon={plantPlain}
            subline={`${dispatchGroup}: ${organizationalUnit}`}
            onClose={close}
            arrowStyle={arrowStyle}
            layerSide={layerSide}
          >
            <div className={classes.details}>
              <p>#{number} </p>
              <p>
                {street}, {postalCode}, {city}
              </p>
            </div>
          </MapCard>
        </div>
      )
    }
  >
    {({toggle, triggerRef}) => (
      <img
        className={classes.plant}
        lat={lat}
        lng={lng}
        role="button"
        alt={number}
        title={number}
        src={iconUrl}
        // only the `triggerRef` is required...
        ref={triggerRef}
        // ...the rest is up to you
        onClick={toggle}
      />
    )}
  </ToggleLayer>
)

Plant.propTypes = {
  classes: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired
}

export default withStyles(styles)(Plant)
